import React from "react";
import IndustrialSolutionsMainBanner from "../templates/OurSegments/IndustrialSolutions/IndustrialSolutionsMainBanner";
import AboutIndustrialSolution from "../templates/OurSegments/IndustrialSolutions/AboutIndustrialSolution";
import IndustrialSolutionGallery from "../templates/OurSegments/IndustrialSolutions/IndustrialSolutionGallery";

function IndustrialSolutions() {
  return (
    <div>
      <IndustrialSolutionsMainBanner />
      <AboutIndustrialSolution />
      <IndustrialSolutionGallery />
    </div>
  );
}

export default IndustrialSolutions;
