import React from "react";
import "./IndustrialSolutionsMainBanner.css";
import Industrial from "../../../images/industrySolution.png";

export default function IndustrialSolutionsMainBanner() {
  return (
    <div>
      <div>
        <div className="IndustrialSolutionsMain">
          <img
            className="IndustrialSolutions-home-image"
            alt=""
            src={Industrial}
          />
          <div className="IndustrialSolutions-image-text-box">
            <div className="IndustrialSolutions-image-background-text">
              <h1 className="IndustrialSolutions-image-topic">
                Industrial Solutions
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
