import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutIndustrialSolution.css";

export default function AboutIndustrialSolution() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="IndustrialSolutionInner-layout-42">
        <div className="IndustrialSolutionInner-content2">
          <div
            className="IndustrialSolutionInner-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="IndustrialSolutionInner-heading">
              Industrial Solutions
            </div>
          </div>
          <div className="IndustrialSolutionInner-column3">
            <div className="IndustrialSolutionInner-text">
              <p
                className="HealthCarIndustrialSolutionInnereInner-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                The Industrial Solutions sector includes construction material,
                industrial raw material, water treatment and packaging. Strong
                relationships and island wide distribution networks support the
                growth of this lucrative sector supported by an increasingly
                wide portfolio of products.
              </p>
              <p className="IndustrialSolutionInner-this-was-done">&nbsp;</p>
              <p
                className="IndustrialSolutionInner-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                The Industrial Solutions sector manufactures and imports a range
                of chemicals and chemical products for the local and export
                markets. Business under globally renowned Akzo Nobel Paints also
                classified under this sector. The Sector has market leadership
                in a number of clusters, driving growth of the country’s
                industrial sector as a key supplier of raw materials for various
                processes and packaging for their finished products. Strong
                relationships support the growth of this lucrative sector
                supported by an increasingly wide portfolio of products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
